import React,  { useEffect } from 'react';
import { connect } from 'react-redux';
import ReactMarkdown from 'react-markdown';

import { Typography, Accordion, AccordionSummary, AccordionDetails, makeStyles } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { FetchFaqs } from '../../../actions/communityActions';

const useStyles = makeStyles({
    container: {
        backgroundColor: 'white',
        marginBottom: '30vh',
    },
    summaryText: {
        fontFamily: 'helvetica-neue-lt-std-65-medium',
        fontSize: 18,
        marginTop: 10,
    },
    innerContainer: {
        fontFamily: 'helvetica-neue-lt-std-65-medium',
        fontSize: 16,
        marginTop: 10,
        whiteSpace: 'pre-wrap',
    },
})

export const Faqs = ({ language, selectedWasteType, recycleFaqs, compostFaqs, fetchFaqs }) => {
    const classes = useStyles();

    useEffect(() => {
        fetchFaqs();
    }, [fetchFaqs, language]);

    return (
        <div className={classes.container} >
            { selectedWasteType === 'recycle' && recycleFaqs &&
            <>
                { recycleFaqs.map((item, i) => {
                    return (
                        <Accordion key={i}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                            >
                                <Typography className={classes.summaryText}>{item.question}</Typography>
                            </AccordionSummary>
                            <AccordionDetails className={classes.innerContainer}>
                                <ReactMarkdown
                                    linkTarget="_blank"
                                >
                                    {item.answer}
                                </ReactMarkdown>
                            </AccordionDetails>
                        </Accordion>
                    )
                })}
            </>
            }

            { selectedWasteType === 'compost' && compostFaqs &&
            <>
                { compostFaqs.map((item, i) => {
                    return (
                        <Accordion key={i}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                            >
                                <Typography className={classes.summaryText}>{item.question}</Typography>
                            </AccordionSummary>
                            <AccordionDetails >
                                <ReactMarkdown
                                    className={classes.innerContainer}
                                    linkTarget="_blank"
                                >
                                    {item.answer}
                                </ReactMarkdown>
                            </AccordionDetails>
                        </Accordion>
                    )
                })}
            </>
            }

        </div>
    )
}

const mapStateToProps = state => {
    return {
        selectedWasteType: state.communities.selectedCommunities[state.communities.activeIndex].waste_type,
        recycleFaqs: state.faqs.recycle,
        compostFaqs: state.faqs.compost,
        language: state.inputs.language ? state.inputs.language : 'en',
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchFaqs: () => {
            dispatch(FetchFaqs());
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Faqs);
